import React, { Component } from "react";
import { Col } from "reactstrap";


interface TeamBoxProps {
  image: string;
  name: string;
  post: string;
}

class TeamBox extends React.Component<TeamBoxProps> {
  render(): JSX.Element {
    const { image, name, post } = this.props;

    return (
      <React.Fragment>
        <Col lg={3} sm={6}>
          <div className="team-box text-center">
            <div className="team-wrapper">
              <div className="team-member">
                <img alt="" src={image} className="img-fluid rounded" />
              </div>
            </div>
            <h4 className="team-name">{name}</h4>
            <p className="text-uppercase team-designation">{post}</p>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default TeamBox;
