// Chakra imports
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
// Assets
import banner from 'assets/img/auth/banner.png';
import profile from 'assets/img/crm/vbz.png';
// Firebase Auth
import {auth} from '../../../../../firebase/firebase';
import { User } from "firebase/auth"; 


// Custom components
import Info from 'views/admin/main/profile/settings/components/Info';
import Password from 'views/admin/main/profile/settings/components/Password';
import Profile from 'views/admin/main/profile/settings/components/Profile';
import Socials from 'views/admin/main/profile/settings/components/Socials';
import Delete from 'views/admin/main/profile/settings/components/Delete';

export default function Settings() {
	const user = auth.currentUser;
	console.log("User: " + user.displayName);
	

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid mb='20px' columns={{ sm: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
				{/* Column Left */}
				<Flex direction='column'>
					<Profile name={user.displayName||user.email} avatar={user.photoURL} banner={banner} />
				    <Info />
				</Flex>
				{/* Column Right */}
				<Flex direction='column'>
					<Socials />
					<Password />
					<Delete />
				</Flex>
			</SimpleGrid>
		</Box>
	);
}
