import React, {useState, useEffect} from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import {auth} from '../../../firebase/firebase';
import {signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider} from 'firebase/auth';
import {signedUpEmail} from '../signUp';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";


function SignIn() {
  const location = useLocation<signedUpEmail>();
  let signedUpEmail = location.state ? location.state.email: '';
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  // Handle login attempt
  const [email, setEmail] = useState(signedUpEmail);
  const [password, setPassword] = useState('');
  const [authenticationStatus, setStatus] = useState(true);
  const history = useHistory();
  const googleProvider = new GoogleAuthProvider();

  // TODO: Make this function generic to work with multiple providers
  const handleSignInWithProvider = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default button behavior
    //const auth = getAuth();
    signInWithPopup(auth, googleProvider)
      .then((userCredential) => {
        // User successfully signed in with the provider
        const user = userCredential.user;
        console.log('User signed in:', user);
      })
      .catch((error) => {
        // Handle sign-in error
        console.error('Error signing in:', error);
      });
  };

  const handleSignIn = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            history.push("/admin");
            console.log(user);
        })
        .catch((error) => {
            setStatus(false);
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
  };

   // TODO: Handle this with PrivateRoute instead.
   useEffect(() => {
      auth.onAuthStateChanged((user) => {
      if (auth.currentUser) {
        // User is logged in
        console.log('User logged in:');
        // Navigate to protected routes
        history.push("/admin/default")
      }
      });
    }, []);
    // unsubscribe();

    return (
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w='100%'
          mx={{ base: 'auto', lg: '0px' }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '14vh' }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign In
            </Heading>
            <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: '100%', md: '420px' }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: 'auto', lg: 'unset' }}
            me='auto'
            mb={{ base: '20px', md: 'auto' }}>
            <Button
              fontSize='sm'
              me='0px'
              mb='26px'
              py='15px'
              h='50px'
              borderRadius='16px'
              bg={googleBg}
              color={googleText}
              fontWeight='500'
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
            onClick={handleSignInWithProvider}>
              <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
              Sign in with Google
            </Button>
            <Flex align='center' mb='25px'>
              <HSeparator />
              <Text color='gray.400' mx='14px'>
                or
              </Text>
              <HSeparator />
            </Flex>
            <FormControl>
              <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='email'
                value={email}
              placeholder={'username@example.com'}
                mb='24px'
                fontWeight='500'
                size='lg'
              onChange={(e) => setEmail(e.target.value)}
            />
              <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? 'text' : 'password'}
                  variant='auth'
                  borderColor={authenticationStatus ? "inherit": 'red'}
                  onChange={(e) => {setPassword(e.target.value);
                }}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                  <Checkbox id='remember-login' colorScheme='brandScheme' me='10px' />
                  <FormLabel
                    htmlFor='remember-login'
                    mb='0'
                    fontWeight='normal'
                    color={textColor}
                    fontSize='sm'>
                    Keep me logged in
                  </FormLabel>
                </FormControl>
                <NavLink to='/auth/forgot-password'>
                  <Text color={textColorBrand} fontSize='sm' w='124px' fontWeight='500'>
                    Forgot password?
                  </Text>
                </NavLink>
              </Flex>
              <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleSignIn}>
                Sign In
              </Button>
            </FormControl>
            <Flex flexDirection='column' justifyContent='center' alignItems='start' maxW='100%' mt='0px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                Not registered yet?
                <NavLink to='/auth/sign-up/default'>
                  <Text color={textColorBrand} as='span' ms='5px' fontWeight='500'>
                    Create an Account
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
    );
  }
  
  export default SignIn;
  
