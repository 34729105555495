import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import SectionTitle from "../common/section-title";
import BlogBox from "./blog-box";

import img1 from 'assets/img/blog/img-1.jpg';
import img2 from 'assets/img/blog/img-2.jpg';
import img3 from 'assets/img/blog/img-3.jpg';

interface BlogData {
  id: number;
  image: string;
  topic: string;
  title: string;
  description: string;
  link: string;
}

interface BlogState {
  blogs: BlogData[];
}

class Blog extends Component<{}, BlogState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      blogs: [
        {
          id: 1,
          image: img1,
          topic: "UI & UX Design",
          title: "Doing a cross country road trip",
          description:
            "She packed her seven versalia, put her initial into the belt and made herself on the way..",
          link: "",
        },
        {
          id: 2,
          image: img2,
          topic: "Digital Marketing",
          title: "New exhibition at our Museum",
          description:
            "Pityful a rethoric question ran over her cheek, then she continued her way.",
          link: "",
        },
        {
          id: 3,
          image: img3,
          topic: "Travelling",
          title: "Why are so many people..",
          description:
            "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia.",
          link: "",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light active" id="blog">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Latest News"
              description="Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean class at a euismod mus luctus quam."
            />

            <Row className="mt-4">
              {/* Render blog boxes */}
              {this.state.blogs.map((blog, key) => (
                <BlogBox key={key} blog={blog} />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
