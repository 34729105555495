import React from "react";
import { Container, Row, Col, Button, FormFeedback, Form, Input } from "reactstrap";
import SectionTitle from "../common/section-title";

import * as Yup from "yup";
import {useFormik} from "formik";

const ContactUs: React.FC = () => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      subject: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
      subject: Yup.string().required("Please Enter Your Subject"),
    }),
    onSubmit: (values: any) => {
      console.log("values", values);
    },
  });

  return (
    <React.Fragment>
      <section className="section " id="contact">
        <Container>
          {/* Render section title */}
          <SectionTitle
            title="Get In Touch"
            description="We thrive when coming up with innovative ideas but also understand that a smart concept should be supported with faucibus sapien odio measurable results."
          />

          <Row>
            <Col lg="4">
              <div className="mt-4 pt-4">
                <p className="mt-4">
                  <span className="h5">Office Address 1:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    4461 Cedar Street Moro, AR 72368
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5">Office Address 2:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    2467 Swick Hill Street <br />
                    New Orleans, LA 70171
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5">Working Hours:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    9:00AM To 6:00PM
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="8">
              <div className="custom-form mt-4 pt-4">
              <p id="error-msg"></p>
                <div id="message"></div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                  <Row>
                    <Col lg="6 mt-2">
                    <Input
                        name="name"
                        className=""
                        placeholder="Your name*"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            validation.touched.name && validation.errors.name ? true : false
                        }
                    />
                    {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                    ) : null}
                    </Col>
                    <Col lg="6 mt-2">
                    <Input
                          name="email"
                          className=""
                          placeholder="Your email*"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                              validation.touched.email && validation.errors.email ? true : false
                          }
                      />
                      {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12 mt-2">
                    <Input
                          name="subject"
                          className=""
                          placeholder="Your Subject.."
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.subject || ""}
                          invalid={
                              validation.touched.subject && validation.errors.subject ? true : false
                          }
                      />
                      {validation.touched.subject && validation.errors.subject ? (
                          <FormFeedback type="invalid">{validation.errors.subject}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12 mt-2">
                      <div className="form-group">
                        <textarea
                          name="comments"
                          id="comments"
                          rows={4}
                          className="form-control"
                          placeholder="Your message..."
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-end">
                      <Button className="submitBnt btn btn-primary">
                        Send Message
                      </Button>
                      <div id="simple-msg"></div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;
