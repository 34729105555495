import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import clients1 from 'assets/img/clients/1.png';
import clients2 from 'assets/img/clients/2.png';
import clients3 from 'assets/img/clients/3.png';
import clients4 from 'assets/img/clients/4.png';

class Clients extends Component {
  state = {
    clients: [
      { id: 1, img: clients1 },
      { id: 2, img: clients2 },
      { id: 3, img: clients3 },
      { id: 4, img: clients4 },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section-sm bg-light">
          <Container>
            <Row>
              {this.state.clients.map((client, key) => (
                <Col md="3" key={key}>
                  <div className="client-images my-3 my-md-0">
                    <img
                      src={client.img}
                      alt="logo-img"
                      className="mx-auto img-fluid d-block"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
