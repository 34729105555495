import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import ScrollspyNav from "./scrollSpy";
import StickyHeader from "react-sticky-header";
import "react-sticky-header/styles.css";

interface NavItemData {
  id: number;
  idnm: string;
  navheading: string;
}

const NavbarPage: React.FC<{ navClass: string }> = (props) => {
  const [navItems, setNavItems] = useState<NavItemData[]>([
    { id: 1, idnm: "home", navheading: "Home" },
    { id: 2, idnm: "features", navheading: "Features" },
    { id: 3, idnm: "services", navheading: "Services" },
    { id: 3, idnm: "about", navheading: "About" },
    { id: 4, idnm: "pricing", navheading: "Pricing" },
    { id: 5, idnm: "blog", navheading: "Blog" },
    { id: 6, idnm: "contact", navheading: "Contact" },
  ]);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const windowScroll = () => {
      const navbar = document.getElementById("navbar");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar?.classList.add("nav-sticky");
      } else {
        navbar?.classList.remove("nav-sticky");
      }
    };

    window.addEventListener("scroll", windowScroll);

    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, []);

  const toggle = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const navigateToSignIn = () => {
    history.push('/auth/sign-in');
  };

  // Store all Navigationbar Id into TargetID variable (Used for Scrollspy)
  const TargetId = navItems.map((item) => {
    return item.idnm;
  });

  return (
    <React.Fragment>
      <StickyHeader
        header={
          <div
            className={
              props.navClass +
              "navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
            }
            id="navbar"
          >
            <Container>
              <NavbarBrand className="logo text-uppercase" href="/">
                <i className="mdi mdi-alien"></i>Tube Analytica
              </NavbarBrand>

              <NavbarToggler
                className=""
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                onClick={toggle}
              >
                <i className="mdi mdi-menu"></i>
              </NavbarToggler>

              <Collapse id="navbarCollapse" isOpen={isOpenMenu} navbar>
                <ScrollspyNav
                  scrollTargetIds={TargetId}
                  activeNavClass="active"
                  scrollDuration={800}
                  headerBackground="true"
                >
                  <Nav className="navbar-nav navbar-center" id="mySidenav">
                    {navItems.map((item, key) => (
                      <NavItem
                        key={key}
                        className={item.navheading === "Home" ? "active" : ""}
                      >
                        <NavLink href={"#" + item.idnm}>
                          {" "}
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </ScrollspyNav>
                <div className="nav-button ms-auto">
                  <Nav className="navbar-right nav" navbar>
                    <NavItem>
                      <Button
                        type="button"
                        color="primary"
                        className="navbar-btn btn-rounded waves-effect waves-light"
                        onClick={navigateToSignIn}
                      >
                        Log In
                      </Button>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </Container>
          </div>
        }
        stickyOffset={-100}
      ></StickyHeader>
    </React.Fragment>
  );
};

export default NavbarPage;
