// Chakra imports
import { Flex, Text, Progress } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import SearchTableUsers from "views/admin/main/users/overview/components/SearchTableUsersOverivew";
import {
    HttpsCallableResult,
    getFunctions,
    httpsCallable,
} from "firebase/functions";

type ChannelObject = {
    channelInfo: [string, string];
    channelId: string;
};

type tableChannelsOverview = {
    name: string[]
} 

export default function UsersOverview() {
    const [youtubeChannels, setYoutubeChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
          try {
            setLoading(true);
          
            const response = await httpsCallable<{}, { channelsResponse: ChannelObject[] }>(
              getFunctions(),
              "getYoutubeChannels"
            )();
            
            console.log("youtubechannels");
            console.log(response.data.channelsResponse);
            
            if (isMounted) {
                setYoutubeChannels(response.data.channelsResponse);
              }
           
          } catch (error) {
            console.error("Error invoking Cloud Function:", error);
          } finally {
            if (isMounted) {
                setLoading(false);
            }
          }
        };
    
        fetchData();
        return () => {
            isMounted = false;
          };
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts

    if (loading) {
        return (
            <Flex direction="column" pt={{ sm: "225px", lg: "75px" }}>
              <Progress size='lg' isIndeterminate  w='100%' />
            </Flex>
        );
      } else if (!youtubeChannels || youtubeChannels.length === 0) {
        return (
            <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
                <Card px="0px">
                    <Text
                        color="secondaryGray.600"
                        fontSize="sm"
                        fontWeight="500"
                    >
                        "No data available"
                    </Text>
                </Card>
            </Flex>
        );
    } else {
        return (
            <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
                <Card px="0px">
                    <SearchTableUsers tableData={youtubeChannels} />
                </Card>
            </Flex>
        );
    }
}
