import React, { Component } from "react";
import { Col } from "reactstrap";

interface ServiceBoxProps {
  icon: string;
  title: string;
  description: string;
}

class ServiceBox extends Component<ServiceBoxProps> {
  render() {
    const { icon, title, description } = this.props;
    return (
      <React.Fragment>
        <Col lg="4" className="mt-4">
          <div className="services-box">
            <div className="d-flex">
              <i className={icon + " text-primary"}></i>
              <div className="ms-4">
                <h4>{title}</h4>
                <p className="pt-2 text-muted">{description}</p>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default ServiceBox;
