import React, { Component } from 'react';
import NavbarPage from "../../components/landing/Navbar/NavbarPage";
import Section from "./section";
import Clients from '../../components/landing/Clients/clients';
import Features from '../../components/landing/Features/features';
import Services from '../../components/landing/Services/services';
import WebsiteDescription from '../../components/landing/Website Description/website-description';
import AboutUs from '../../components/landing/About Us/about-us';
import Pricing from '../../components/landing/Pricing/pricing';
import Testimonials from '../../components/landing/Testimonials/testimonials';
import GetStarted from '../../components/landing/Get Started/get-started';
import Blog from '../../components/landing/Blog/blog';
import ContactUs from '../../components/landing/Contact Us/contact-us';
import Footer from '../../components/landing/Footer/footer';

interface LandingPageState {
    navClass: string;
}

class LandingPage extends Component<{}, LandingPageState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            navClass: ""
        };
    }

    componentDidMount() {
        const colorTheme = document.getElementById("colorTheme") as HTMLLinkElement | null;
        if (colorTheme) {
            colorTheme.href = "assets/colors/red.css";
        }
    }

    render() {
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                <NavbarPage navClass = {this.state.navClass}/>

                {/* Importing Section */ }
                <Section/>
                
                {/* Importing Client */}
                <Clients/>
                
                {/* Importing Features */}
                <Features/>

                {/* Importing Services */}
                <Services/>

                {/* Importing Website Description */}
                <WebsiteDescription/>

                {/* Importing About Us */}
                <AboutUs/>

                {/* Importing Pricing */}
                <Pricing/>

                {/* Importing Testimonials */}
                <Testimonials/>

                {/* Importing Get Started */}
                <GetStarted/>

                {/* Importing Blog */}
                <Blog/>

                {/* Importing Contact Us */}
                <ContactUs/> 

                {/* Importing Get Footer */}
                <Footer/>

            </React.Fragment>
        );
    }
}

export default LandingPage;