import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

interface PricingBoxProps {
  pricing: {
    id: number,
    isRibbon?: boolean;
    title: string;
    tagline: string;
    price: string;
    duration: string;
    features: {
      channels: string;
      dataFreshness: string;
      features: string;
      retroactive: string;
      hiddenFees: string;
    };
  };
}

class PricingBox extends Component<PricingBoxProps> {
  render() {
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="text-center pricing-box ">
            {this.props.pricing.isRibbon ? (
              <div className="ribbon-box">
                <span>Popular</span>
              </div>
            ) : null}
            <h4 className="text-uppercase">{this.props.pricing.title}</h4>
            <h1>${this.props.pricing.price}</h1>
            <h6 className="text-uppercase text-muted">
              Billing Per {this.props.pricing.duration}
            </h6>
            <div className="pt-4" >
              <p>{this.props.pricing.tagline}</p>
            </div>
            <div className="plan-features mt-5">
              <p>
                Number of Channels:{" "}
                <b className="text-primary">
                  {this.props.pricing.features.channels}
                </b>
              </p>
              <p>
                Data Freshness:{" "}
                <b className="text-primary">
                  {this.props.pricing.features.dataFreshness}
                </b>
              </p>
              <p>
                Features:{" "}
                <b className="text-primary">
                  {this.props.pricing.features.features}
                </b>
              </p>
              <p>
                Retroactive Analysis
                <b className="text-primary">
                  {this.props.pricing.features.retroactive}
                </b>{" "}
              </p>
              <p>
                <b className="text-primary">
                  {this.props.pricing.features.hiddenFees}
                </b>{" "}
                Hidden Fees
              </p>
            </div>
            <Link
              to="#"
              className="btn btn-primary waves-effect waves-light mt-5"
            >
              Join Now
            </Link>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default PricingBox;

