import React, { Component } from "react";
import { Container, Row } from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";

// Import Pricing Box
import PricingBox from "./pricing-box";

interface PricingState {
  pricings: {
    id: number;
    title: string;
    tagline: string;
    price: string;
    duration: string;
    isRibbon?: boolean;
    features: {
      channels: string;
      dataFreshness: string;
      features: string;
      retroactive: string;
      hiddenFees: string;
    };
  }[];
}

class Pricing extends Component<{}, PricingState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      pricings: [
        {
          id: 1,
          title: "Analytica Starter",
          tagline: "Begin your YouTube journey with foundational analytics from Tube Analytica.",
          price: "9.90",
          duration: "MONTH",
          features: {
            channels: "1",
            dataFreshness: "24h",
            features: "Basic",
            retroactive: "No",
            hiddenFees: "No",
          },
        },
        {
          id: 2,
          title: "Analytica Pro",
          tagline: "Dive deeper into your YouTube analytics with advanced tools from Tube Analytica.",
          price: "19.90",
          duration: "MONTH",
          isRibbon: true,
          features: {
            channels: "5",
            dataFreshness: "8h",
            features: "Pro",
            retroactive: "Yes",
            hiddenFees: "No",
          },
        },
        {
          id: 3,
          title: "Analytica Mastery",
          tagline: "Master your YouTube strategy with the complete analytics suite from Tube Analytica",
          price: "29.90",
          duration: "MONTH",
          features: {
            channels: "Unlimited",
            dataFreshness: "4h",
            features: "All",
            retroactive: "Yes",
            hiddenFees: "No",
          },
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="pricing">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Our Pricing"
              description="Call to action pricing table is really crucial to your for your business website. Make your bids stand-out with amazing options."
            />

            <Row className="mt-5">
              {/* Render Pricing Box */}
              {this.state.pricings.map((pricing, key) => (
                <PricingBox key={key} pricing={pricing} />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Pricing;
