import React, { useEffect } from "react";

interface ScrollspyNavProps {
  scrollTargetIds: string[]; // Array of scroll target IDs
  activeNavClass: string; // Class for active nav element
  scrollDuration?: number; // Scroll animation duration (ms)
  router?: 'HashRouter' | undefined; // Router type, if used
  className?: string; // Additional class for the navbar
  children: React.ReactNode; // Children of the component
  headerBackground?: string
}

const ScrollspyNav: React.FC<ScrollspyNavProps> = ({
  scrollTargetIds,
  activeNavClass,
  scrollDuration = 1000,
  router,
  className,
  children,
  headerBackground,
}) => {

  const easeInOutQuad = (
    currentTime: number,
    start: number,
    change: number,
    duration: number
  ) => {
    currentTime /= duration / 2;
    if (currentTime < 1)
      return (change / 2) * currentTime * currentTime + start;
    currentTime--;
    return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
  };

  const scrollTo = (start: number, to: number, duration: number) => {
    let change = to - start,
      currentTime = 0,
      increment = 10;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(
        currentTime,
        start,
        change,
        duration
      );
      window.scrollTo(0, val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  };

  const getNavLinkElement = (sectionID: string) =>
    document.querySelector(`a[href='${router ? "#" : ""}${sectionID}']`);

  const getNavToSectionID = (navHref: string) =>
    navHref.includes(router ? "#" : "")
      ? navHref.replace(router ? "#" : "", "")
      : "";

  useEffect(() => {
    const homeDefaultLink = router && router === "HashRouter" ? "#/home/default" : "/home/default";
    const headerBackground = false; // assuming headerBackground initialization

    if (document.querySelector(`a[href='${homeDefaultLink}']`)) {
      document
        .querySelector(`a[href='${homeDefaultLink}']`)
        .addEventListener("click", (event) => {
          event.preventDefault();
          scrollTo(window.pageYOffset, 0, scrollDuration);
          window.location.hash = "";
        });
    }

    document
      .querySelector("div[data-nav='list']")
      .querySelectorAll("a")
      .forEach((navLink) => {
        navLink.addEventListener("click", (event) => {
          event.preventDefault();
          let sectionID = getNavToSectionID(navLink.getAttribute("href"));

          if (sectionID) {
            let scrollTargetPosition =
              document.getElementById(sectionID)?.offsetTop -
              (headerBackground
                ? document.querySelector("div[data-nav='list']")
                    .scrollHeight
                : 0) || 0;
            scrollTo(
              window.pageYOffset,
              scrollTargetPosition,
              scrollDuration
            );
          } else {
            scrollTo(window.pageYOffset, 0, scrollDuration);
          }
        });
      });

    const handleScroll = () => {
      scrollTargetIds.forEach((sectionID, index) => {
        let scrollSectionOffsetTop =
          (document.getElementById(sectionID)?.offsetTop || 0) -
          (headerBackground
            ? document.querySelector("div[data-nav='list']")
                .scrollHeight
            : 0);

        if (
          window.pageYOffset >= scrollSectionOffsetTop &&
          window.pageYOffset <
            scrollSectionOffsetTop +
              (document.getElementById(sectionID)?.scrollHeight || 0)
        ) {
          const navElement = getNavLinkElement(sectionID);
          navElement?.classList.add(activeNavClass);
          navElement?.parentElement?.classList.add(activeNavClass);
          clearOtherNavLinkActiveStyle(sectionID);
        } else {
          const navElement = getNavLinkElement(sectionID);
          navElement?.classList.remove(activeNavClass);
          navElement?.parentElement?.classList.remove(activeNavClass);
        }

        if (
          index === scrollTargetIds.length - 1 &&
          window.innerHeight + window.pageYOffset >= document.body.scrollHeight
        ) {
          const navElement = getNavLinkElement(sectionID);
          navElement?.classList.add(activeNavClass);
          navElement?.parentElement?.classList.add(activeNavClass);
          clearOtherNavLinkActiveStyle(sectionID);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollTargetIds, activeNavClass, scrollDuration, router]);

  const clearOtherNavLinkActiveStyle = (excludeSectionID: string) => {
    scrollTargetIds
      .filter((sectionID) => sectionID !== excludeSectionID)
      .forEach((sectionID) => {
        const navElement = getNavLinkElement(sectionID);
        navElement?.classList.remove(activeNavClass);
        navElement?.parentElement?.classList.remove(activeNavClass);
      });
  };

  return (
    <div data-nav="list" className={className}>
      {children}
    </div>
  );
};

export default ScrollspyNav;
