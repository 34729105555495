// Chakra imports
import {
    Button,
    Flex,
    FormControl,
    Icon,
    Text,
    useColorModeValue,
    Progress
} from "@chakra-ui/react";
import Card from "components/card/Card";
import InputField from "components/fields/InputField";
import { functions } from "../../../../../../firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState, useEffect } from "react";

import { FaYoutube } from "react-icons/fa";
import { FirebaseError } from "firebase/app";

export default function Settings() {
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.600";

    const googleBg = useColorModeValue("navy.700", "navy.600");
    //const googleText = useColorModeValue("navy.700", "white");
    const googleHover = useColorModeValue(
        { bg: "gray.200" },
        { bg: "whiteAlpha.300" }
    );
    const googleActive = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.200" }
    );

    const functions = getFunctions();
    const getIsYoutubeAccountLinked = httpsCallable<{}, { value: boolean }>(
        functions,
        "isYoutubeAccountLinked"
    );
    const getAuthUrl = httpsCallable<{}, { authUrl: string }>(
        functions,
        "getAuthUrl"
    );

    const [isYoutubeAccountLinked, setIsYoutubeAccountLinked] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const response = await getIsYoutubeAccountLinked();
                console.log("isyoutubeaccountlinked");
                console.log(response);
                if (isMounted) {
                    const data: boolean = response.data.value;
                    setIsYoutubeAccountLinked(data);
                    console.log(isYoutubeAccountLinked);
                }
            } catch (error) {
                console.error(error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchData();
        return () => {
            isMounted = false;
        };
    }, []); // Empty dependency array ensures that this effect runs once when the component mounts

    const handleYtAccountLinking = async () => {
        getAuthUrl()
            .then((response) => {
                // Redirect the user to the authorization URL
                const data = response.data;
                window.location.href = data.authUrl;
            })
            .catch((Error) => {
                console.error(Error);
            });
    };

    if (loading) {
        return (
            <Card mb="20px" pb="70px">
            <Flex direction="column" pt={{ sm: "225px", lg: "75px" }}>
                <Progress size="lg" isIndeterminate w="100%" />
            </Flex>
            </Card>
        );
    } else {
        return (
            <FormControl>
                <Card mb="20px" pb="50px">
                    <Flex direction="column" mb="40px" ms="10px">
                        <Text
                            fontSize="xl"
                            color={textColorPrimary}
                            fontWeight="bold"
                        >
                            Link Youtube Account
                        </Text>
                        <Text fontSize="md" color={textColorSecondary}>
                            {isYoutubeAccountLinked
                                ? "Youtube account linked"
                                : "Authorize YouTube to share data about your Youtube channels with Tube Analytica"}
                        </Text>
                    </Flex>
                    {/* <InputField mb='25px' id='youtube_username' label='Youtube Username' placeholder='Youtube Username' /> */}
                    <Button
                        onClick={handleYtAccountLinking}
                        variant="brand"
                        fontSize="sm"
                        me="0px"
                        mb="26px"
                        py="15px"
                        h="50px"
                        borderRadius="16px"
                        // bg={googleBg}
                        // color={googleText}
                        fontWeight="500"
                        isDisabled={isYoutubeAccountLinked}
                    >
                        <Icon as={FaYoutube} w="20px" h="20px" me="10px" />
                        Link YouTube Account
                    </Button>
                    {/* <InputField
					mb='25px'
					id='facebook_username'
					label='Facebook Username'
					placeholder='Facebook Username'
				/>
				<InputField mb='25px' id='github_username' label='Github Username' placeholder='Github Username' /> */}
                    {/* <Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto'>
					Save changes
				</Button> */}
                </Card>
            </FormControl>
        );
    }
}
