import React, {ReactElement} from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import {auth} from './firebase/firebase';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import HomeLayout from './layouts/home';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<HashRouter>
				<Switch>
					<Route path={`/auth`} component={AuthLayout} >
						{/* {auth.currentUser ? <Redirect to="/admin/default"/>: "" } */}
						</Route>
					<Route path={'/home'} component={HomeLayout} />
					<PrivateRoute path={`/admin`} component={AdminLayout} />
					<PrivateRoute path={`/admin/default`} component={AdminLayout} />
					<Redirect from='/' to='/home' />
				</Switch>
			</HashRouter>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);

interface IProps {
	exact?: boolean;
	path: string;
	component: React.ComponentType<any>;
  }

// TODO(Move to this into its own component under components)
function PrivateRoute({
	exact = false,
	path,
	component
  }: IProps) {
	return (
	  <Route
		exact={exact}
		path={path}
		render={(props) => {
		  if (auth.currentUser) {
			return <Route {...props} component={component} />;
		  } else if (!auth.currentUser) {
			return (
			  <Redirect
				to={{
				  pathname: "/login",
				  state: { from: props.location }
				}}
			  />
			);
		  } else {
			return null;
		  }
		}}
	  />
	);
  }
