import React, { Component, RefObject } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import wave1 from 'assets/img/wave-shape/wave1.png';
import wave2 from 'assets/img/wave-shape/wave2.png';
import wave3 from 'assets/img/wave-shape/wave3.png';

// Importing Modal
import ModalSection from "../../components/landing/common/ModalSection";

interface SectionState {
  isOpen: boolean;
}

class Section extends Component<{}, SectionState> {
  private childRef: RefObject<ModalSection>;

  constructor(props: {}) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.childRef = React.createRef<ModalSection>();
  }

  callModal = () => {
    if (this.childRef.current) {
      this.childRef.current.openModal();
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="section bg-home home-half" id="home">
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col lg={{ size: 8, offset: 2 }} className="text-white text-center">
                <h4 className="home-small-title">Tube Analytica</h4>
                <h1 className="home-title">Youtube Analytics But Better</h1>
                <p className="pt-3 home-desc mx-auto">
                  Grow your audience with advanced analytics and insights geared towards your channel growth.
                </p>
                <p className="play-shadow mt-4">
                  <Link
                    onClick={this.callModal}
                    to="#"
                    className="play-btn video-play-icon"
                  >
                    <i className="mdi mdi-play text-center"></i>
                  </Link>
                </p>
              </Col>
              {/* Render ModalSection Component for Modal */}
              <ModalSection ref={this.childRef} channel="vimeo" videoId="99025203" />
            </Row>
          </Container>
          <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div
                className="wave wave-one"
                style={{
                  backgroundImage: `url(${wave1})`,
                }}
              ></div>
            </div>
            <div className="waves-shape shape-two">
              <div
                className="wave wave-two"
                style={{
                  backgroundImage: `url(${wave2})`,
                }}
              ></div>
            </div>
            <div className="waves-shape shape-three">
              <div
                className="wave wave-three"
                style={{
                  backgroundImage: `url(${wave3})`,
                }}
              ></div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
