import React, { Component } from 'react';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';

//const ModalVideo = require('react-modal-video');
import ModalVideo from 'react-modal-video';

interface ModalSectionProps {
  channel: "youtube" | "vimeo" | "youku" | "custom";
  videoId: string;
}

interface ModalSectionState {
  isOpen: boolean;
}

class ModalSection extends Component<ModalSectionProps, ModalSectionState> {
  constructor(props: ModalSectionProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <ModalVideo
          // channel={this.props.channel}
          isOpen={this.state.isOpen}
          videoId={this.props.videoId}
          onClose={() => this.setState({ isOpen: false })}
        />
      </React.Fragment>
    );
  }
}

export default ModalSection;
